<template>
  <div class="container">
    <div class="row mt-md-3">
      <div class="col-md-8 mx-auto">
        <div class="card border border-0">
          <div class="card-body px-md-5 py-md-3">
            <div v-if="!tfaToken">
              <div>
                <div class="row mb-4">
                  <div class="col-auto mx-auto text-center">
                    <h3 class="fw-bold">Amchara Staff Portal Login</h3>
                  </div>
                </div>
              </div>
              <form @submit.prevent="requestLoginToken" v-if="!requested">
                <div class="row mb-3">
                  <div class="col my-auto">
                    <label for=""
                      >Enter your email address to request a secure login
                      link</label
                    >
                    <input
                      id="email"
                      type="email"
                      class="form-control"
                      name="email"
                      required
                      autocomplete="email"
                      autofocus
                      placeholder="jane@example.com"
                      v-model="form.email"
                    />
                  </div>
                </div>

                <div class="row mt-3 mb-4 text-center">
                  <div class="col-md-6 mx-auto">
                    <button
                      type="submit"
                      class="btn btn-outline-success btn-block"
                      :disabled="busy"
                    >
                      <i
                        class="fad me-2"
                        :class="busy ? 'fa-spin fa-spinner' : 'fa-key'"
                      ></i>
                      Request Login Link
                    </button>
                  </div>
                </div>
              </form>

              <div
                class="row mb-4"
                v-if="!requested && form.email.length === 0"
              >
                <div class="col-auto mx-auto">
                  <google-login
                    :params="googleParams"
                    class="btn btn-outline-success"
                    :onSuccess="googleOnSuccess"
                    :onFailure="googleOnFailure"
                    ><i class="fab fa-google me-2"></i>Login with
                    Google</google-login
                  >
                </div>
              </div>

              <div class="row" v-if="!requested">
                <div class="col">
                  <p class="px-md-4 text-black">
                    <span class="fw-bold">No password, wait, what?</span
                    ><br />That’s right, no password! When you want to login to
                    Amchara, we send you an email that has a special login link.
                    Clicking on that link will sign you in. That’s all there is
                    to it.
                    <br />
                    Alternatively you can use your Google account to login.
                  </p>
                </div>
              </div>

              <div v-if="requested">
                <div class="row mb-3">
                  <div class="col">
                    <h5>Method One: Magic Link</h5>
                    <p class="alert alert-info">
                      <i class="fad fa-info me-2"></i>
                      We've sent you an email that contains a secure login link.
                      You may close this tab and follow that link to securely
                      gain access.
                    </p>
                  </div>
                </div>
                <form @submit.prevent="loginWithOtp">
                  <div class="row">
                    <div class="col">
                      <h5>Method Two: One-Time Password</h5>
                      <p class="alert alert-info">
                        <i class="fad fa-info me-2"></i>
                        Enter the One-Time Password contained in the email that
                        we've sent you.
                      </p>
                      <input
                        id="otp"
                        type="password"
                        class="form-control w-50 mx-auto mb-3"
                        name="otp"
                        required
                        autofocus
                        placeholder="Enter your One-Time Password..."
                        v-model="form.otp"
                      />
                    </div>
                  </div>
                  <div class="row text-center mb-3">
                    <div class="col">
                      <button class="btn btn-success mx-auto">
                        Login with Secure OTP
                      </button>
                    </div>
                  </div>
                </form>
                <div class="row text-center">
                  <div class="col">
                    <button
                      class="btn btn-sm btn-info"
                      v-if="!smsSent"
                      :disabled="busy"
                      @click="requestSmsOtp"
                    >
                      Request SMS
                    </button>
                    <div v-if="smsSent" class="rounded alert alert-info mt-2">
                      <div>
                        Thank you, your SMS is on it's way. If you have
                        registered a mobile number with us you will receive an
                        SMS with your One-Time Password to enter above.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="tfaToken" class="mb-3">
              <div class="row">
                <div class="col">
                  <div class="alert alert-info" role="alert">
                    <h4 class="alert-heading">Two-Factor Authentication</h4>
                    <p>
                      We have sent you a secure code via SMS. Please enter the
                      secure code into the box below to gain access. This code
                      will expire in 5 minutes.
                    </p>
                    <hr />
                    <p class="mb-0">
                      You are seeing this because you require 2FA in your User
                      Settings.
                    </p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col text-center">
                  <form @submit.prevent="authorize2fa">
                    <input
                      type="number"
                      v-model="tfaCode"
                      placeholder="000000"
                      class="form-control w-25 mx-auto text-center mb-3"
                      required
                    />
                    <button type="submit" class="btn btn-success">
                      Authorize 2FA
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <div class="row" v-if="error">
              <div class="col">
                <p class="alert alert-danger">
                  <i class="fad fa-info me-2"></i>
                  {{ error }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/styles/tailwind.css";
import SuccessAlert from "../components/alerts/Success";
import ErrorAlert from "../components/alerts/Error";
import GoogleLogin from "vue-google-login";

export default {
  data() {
    return {
      form: {
        email: "",
        otp: "",
        requester: "staff-portal",
      },
      requested: false,
      error: null,
      tfaToken: "",
      tfaCode: "",
      busy: false,

      googleParams: {
        client_id:
          "506056635281-ql2vl3qvvnne3nmi3f2mcb61r5e0t7vu.apps.googleusercontent.com",
      },
      googleRenderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
      smsSent: false,
    };
  },
  methods: {
    googleOnSuccess(googleUser) {
      this.busy = true;
      var profile = googleUser.getBasicProfile();
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/google-login", {
          requester: "staff-portal",
          email: profile.getEmail(),
          token: googleUser.getAuthResponse().id_token,
        })
        .then(({ data }) => {
          if (data.error == "invalid-credentials") {
            this.error = "Invalid user or credentials";
            if (localStorage.getItem("googleLogin")) {
              var auth2 = gapi.auth2.getAuthInstance();
              auth2.signOut().then(function() {
                console.log("User signed out.");
              });
            }
            localStorage.removeItem("googleLogin");
            this.requested = false;
          } else {
            this.$store.user = data.user;
            this.$store.token = data.token;
            localStorage.setItem("user", JSON.stringify(data));
            if (data.token) {
              this.$axios.defaults.headers.Authorization = `Bearer ${data.token.access_token}`;
            }
            localStorage.setItem("googleLogin", true);
            this.requested = true;
            this.$router.push({ name: "DashboardView" });
          }
          this.busy = false;
        });
    },
    googleOnFailure() {},
    requestLoginToken() {
      this.busy = true;
      this.error = null;
      this.requested = false;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/request-login-token", this.form)
        .then(({ data }) => {
          console.log(data);
          if (data.error) {
            this.error = data.error;
          } else {
            this.requested = true;
          }
          this.busy = false;
        });
    },
    requestSmsOtp() {
      this.busy = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/request-sms-otp", this.form)
        .then(({ data }) => {
          this.busy = false;
          this.smsSent = true;
          this.$EventBus.$emit("alert", data);
        });
    },
    loginWithOtp() {
      this.busy = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/login-otp", this.form)
        .then(({ data }) => {
          if (data.error == "invalid-otp") {
            this.error = "Invalid OTP";
          } else if (data.error == "tfa_required") {
            window.location = data.url;
          } else if (!data.error) {
            this.$store.user = data.user;
            this.$store.token = data.token;
            localStorage.setItem("user", JSON.stringify(data));
            if (data.token) {
              this.$axios.defaults.headers.Authorization = `Bearer ${data.token.access_token}`;
            }
            this.$router.push({ name: "DashboardView" });
          }
          this.busy = false;
        });
    },
    authorize2fa() {
      this.busy = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/login-2fa", {
          token: this.tfaToken,
          code: this.tfaCode,
        })
        .then(({ data }) => {
          if (data.error == "invalid-credentials") {
            this.error = "Invalid code";
          } else if (data.error == "invalid-2fa") {
            this.error = "Invalid 2FA Code";
          } else if (!data.error) {
            this.$store.user = data.user;
            this.$store.token = data.token;
            localStorage.setItem("user", JSON.stringify(data));
            if (data.token) {
              this.$axios.defaults.headers.Authorization = `Bearer ${data.token.access_token}`;
            }
            this.$router.push({ name: "DashboardView" });
          }
          this.busy = false;
        });
    },
    getParams() {
      const params = new URLSearchParams(window.location.search);
      if (params.has("require2fa")) {
        this.tfaToken = params.get("require2fa");
      }
    },
  },
  mounted() {
    this.getParams();
  },
  components: {
    SuccessAlert,
    ErrorAlert,
    GoogleLogin,
  },
};
</script>

<style lang="scss">
.email-input {
  border: 2px solid #03006b;
  border-radius: 1.25rem;
  padding: 2rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.email-input {
  color: #03006b;
}

.email-input:focus {
  color: #03006b;
  outline: none !important;
}
</style>
